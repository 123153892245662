<template>
  <teleport to="body">
    <job-person-spec-modal
      @closeModal="toggleJobSpecModal(false)"
      v-if="jobSpecModalOpen"
      :jobSpecification="
        getNewHiringRequest?.singleJobSpec ||
        getNewHiringRequest?.job_specification
      "
      :personSpecification="
        getNewHiringRequest?.singlePersonSpec ||
        getNewHiringRequest?.person_specification
      "
    />
  </teleport>
  <div class="flex justify-center items-center self-start w-full">
    <div class="rounded-xl bg-lightGrey text-grey w-9/12 my-10">
      <!-- Request Form -->
      <div class="p-2">
        <h1 class="font-bold">Staff Request Form</h1>
      </div>
      <!-- Icon / Request Info -->
      <div class="flex w-full mb-2">
        <div class="text-center text-grey bg-white rounded-xl ml-2">
          <p class="text-grey font-semibold text-lg mx-3">
            {{ getNewHiringRequest?.contract_type }}
          </p>
          <div class="relative">
            <span class="material-icons text-7xl mx-3" :class="iconClass">
              person
            </span>
          </div>
        </div>
        <div class="bg-white rounded-xl w-full flex flex-col mx-2">
          <h3 class="font-bold text-lg pl-2 mt-2 mb-1">Request Information</h3>
          <div class="flex pl-2 mb-2">
            <p class="text-ellipsis">
              Job title: <span>{{ getNewHiringRequest?.job_title }}</span>
            </p>
          </div>
          <div class="flex pl-2">
            <p>
              Reports to:
              <span>{{
                typeof getNewHiringRequest?.reporting_to === "object"
                  ? `${getNewHiringRequest?.reporting_to?.profile?.first_name} ${getNewHiringRequest?.reporting_to?.profile?.last_name} (${getNewHiringRequest?.reporting_to?.email})`
                  : getNewHiringRequest?.reporting_to
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- Manager Statement -->
      <div class="bg-white rounded-xl m-2 pb-2 min-h-1/4 flex flex-col">
        <h3 class="font-bold mt-2 ml-2 text-lg">Manager Statement</h3>
        <p class="mx-2">
          Submitted: {{ convertedTime().format("DD/MM/YYYY") }}
        </p>
        <div class="flex">
          <p class="mx-2">
            <b>Reason For Recruitment: </b>
            <span>{{ getNewHiringRequest?.reason_for_recruitment }}</span>
          </p>
        </div>
        <div
          class="
            border border-grey
            rounded-l
            mx-2
            flex-grow
            h-32
            overflow-scroll
          "
        >
          <p class="ml-2">
            <span v-html="getNewHiringRequest?.comment"></span>
          </p>
        </div>
      </div>
      <!-- Job Spec / Salary Info / Rota Info -->
      <div class="mx-2">
        <!-- Job Spec -->
        <div class="flex">
          <div
            class="
              bg-white
              rounded-xl
              text-center
              h-28
              flex
              justify-center
              items-center
              m-1
              w-1/2
            "
          >
            <div>
              <h3 class="font-bold text-lg">Job Specification</h3>
              <p
                @click="toggleJobSpecModal(true)"
                class="text-teal text-xs mt-2 cursor-pointer hover:underline"
              >
                View more
              </p>
            </div>
          </div>
          <!-- Salary Info -->
          <div
            class="
              flip
              cursor-pointer
              bg-white
              rounded-xl
              text-center
              h-28
              flex
              justify-center
              items-center
              m-1
              w-1/2
            "
          >
            <div
              class="
                flip-content
                w-full
                h-full
                transition transition-transform
                duration-300
              "
            >
              <div
                class="
                  flip-front
                  w-full
                  h-full
                  flex flex-col
                  justify-center
                  items-center
                "
              >
                <h3 class="font-bold text-lg">Salary Information</h3>
                <p class="text-teal text-xs mt-2 underline">Hover</p>
              </div>
              <div
                class="flip-back w-full h-full flex justify-center items-center"
              >
                <p class="text-grey">
                  {{ getNewHiringRequest?.starting_salary }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Rota Info -->
        <div class="bg-white rounded-xl m-1">
          <h3 class="font-bold mt-2 ml-2 mb-1 text-lg">Rota Information</h3>
          <div v-if="getNewHiringRequest?.work_patterns" class="flex">
            <p class="ml-2">Rota Name: {{ getNewHiringRequest?.work_patterns?.[0]?.name }}</p>
          </div>
          <div class="flex justify-center py-5">
            <!-- Rota Information -->

            <rotaCardWithData
              v-if="getNewHiringRequest?.work_patterns"
              :showSelect="false"
              :bgColour="'bg-white'"
              :showTitle="false"
              :data="getNewHiringRequest?.work_patterns?.[0]?.work_timings"
            ></rotaCardWithData>
            <rota-card
              v-else
              :showSelect="false"
              :bgColour="'bg-white'"
              :showTitle="false"
            ></rota-card>
          </div>
        </div>
      </div>
      <div class="flex justify-center m-2">
        <router-link
          v-if="!submitAction"
          :to="{ name: 'ma-new-staff-summary' }"
        >
          <base-button @click="showConfirmationDialog">{{
            buttonText ? buttonText : "Submit"
          }}</base-button>
        </router-link>
        <base-button v-else @click="close()">{{
          buttonText ? buttonText : "Submit"
        }}</base-button>
      </div>
    </div>
  </div>
  <base-confirmation-dialog
    :show="confirmationDialog == true && buttonText != 'Close'"
    title="Are you sure?"
  >
    <base-button @click="submitAction ? clickWithProps() : confirmHire()"
      >Submit</base-button
    >
    <!-- 'ma-new-hire -->
    <base-button :mode="'cancel'" @click="closeConfirmationDialog"
      >Cancel</base-button
    >
  </base-confirmation-dialog>
</template>

<script>
import jobPersonSpecModal from "@/components/modal/jobPersonSpecModal.vue";
import store from "@/store/index.js";
import moment from "moment";
import rotaCard from "@/components/ui/baseComponents/rotaCard.vue";
import rotaCardWithData from "@/components/ui/baseComponents/rotaCardWithData.vue";
import baseConfirmationDialog from "@/components/ui/baseComponents/baseConfirmationDialog.vue";
export default {
  props: {
    buttonText: {
      type: String,
      default: null,
    },
    submitAction: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submitAction"],
  created() {
    this.hiringRequest = Object.assign(
      this.hiringRequest,
      this.getNewHiringRequest
    );
    this.hiringRequest.reporting_to =
      this.getNewHiringRequest?.reporting_to?.id;
  },
  components: {
    rotaCard,
    baseConfirmationDialog,
    jobPersonSpecModal,
    rotaCardWithData,
  },
  data() {
    return {
      jobSpecModalOpen: false,
      reasonForRecruitment: null,
      confirmationDialog: false,
      hiringRequest: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    // Check if hiringRequest Object in store is NULL.
    const objectIsNull = Object.values(
      store.getters["MAnewHire/getNewHiringRequest"]
    ).every((value) => {
      if (value === null) {
        return true;
      }
      return false;
    });
    // Redirect back to new hire with status error for notification
    if (objectIsNull) {
      next({
        name: "ma-new-hire",
        query: { error: "true" },
      });
    } else {
      next();
    }
  },
  methods: {
    clickWithProps() {
      this.closeConfirmationDialog();
      this.$emit("submitAction");
    },
    toggleJobSpecModal(value) {
      this.jobSpecModalOpen = value;
    },
    showConfirmationDialog() {
      this.confirmationDialog = true;
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false;
    },
    async confirmHire() {
      this.closeConfirmationDialog();
      try {
        await this.$store.dispatch(
          "MAnewHire/createHiringRequest",
          this.hiringRequest
        );
        this.$router.push({
          name: "ma-new-hire",
          query: { new: "true" },
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.$router.push({
          name: "ma-new-hire",
          query: { error: "true" },
        });
      }
    },
    close() {
      this.$router.push({
        name: "ma-new-hire",
      });
    },
  },
  computed: {
    getNewHiringRequest() {
      return this.$store.getters["MAnewHire/getNewHiringRequest"];
    },
    iconClass() {
      return {
        "text-teal": this.getNewHiringRequest?.contract_type === "Permanent",
        "text-orange": this.getNewHiringRequest?.contract_type === "Casual",
        "text-green": this.getNewHiringRequest?.contract_type === "Fixed-term",
        "text-red": this.getNewHiringRequest?.contract_type === "Zero-hour",
      };
    },
    convertedTime() {
      return moment;
    },
  },
};
</script>

<style scoped>
.flip-content {
  transform-style: preserve-3d;
}
.flip:hover .flip-content {
  transform: rotateY(180deg);
  transition: transform 0.3s;
}
.flip-front,
.flip-back {
  position: absolute;

  backface-visibility: hidden;
}
.flip-back {
  transform: rotateY(180deg);
}
</style>
