<template>
  <div @click="closeDialog" class="fixed inset-0 bg-grey bg-opacity-50 overflow-y-auto">
    <div 
        @click.stop="openDialog" class="relative top-10 mx-auto p-5 w-3/4 rounded-md">
      <div
        class="absolute bg-lightGrey left-28 my-5 py-5 rounded-lg w-full z-10"
      >
        <div class="flex flex-col w-11/12 m-auto">
          <h1 class="text-grey font-bold ml-10 mb-3">Specification Name</h1>

          <!-- Basic Info -->
          <ul class="m-auto mt-8 lg:grid w-full">
            <div class="flex items-center justify-between">
              <p class="text-darkGrey">Title:</p>
              <p class="text-grey">{{ jobSpecification.title }}</p>
            </div>

            <div class="flex items-center justify-between">
              <p class="text-darkGrey">Salary Grade:</p>
              <p class="text-grey">{{ jobSpecification.salary_grade }}</p>
            </div>
            <!-- <div class="flex items-center justify-between">
              <p class="text-darkGrey">Rota Name:</p>
              <p class="text-grey">{{jobSpecification.rota_name}}</p>
            </div> -->
            <div class="flex items-center justify-between">
              <p class="text-darkGrey">Total Hours:</p>
              <p class="text-grey">{{ jobSpecification.total_hours }}</p>
            </div>
            <div class="flex items-center justify-between">
              <p class="text-darkGrey">Location:</p>
              <p class="text-grey">{{ jobSpecification.location }}</p>
            </div>
            <div class="flex items-center justify-between">
              <p class="text-darkGrey">Job Purpose:</p>
              <p class="text-grey">{{ jobSpecification.job_purpose }}</p>
            </div>
          </ul>
        </div>
        <!-- Responsibilities -->
        <div class="flex flex-col w-11/12 m-auto">
          <div class="flex justify-between items-center text-grey">
            <h1 class="ml-10 font-semibold my-3">Responsibilities:</h1>
            <!-- <div class="flex">
          <p class="text-darkGrey">Add Row</p>
          <span
            @click="addResponsibility"
            class="material-icons text-orange ml-3 cursor-pointer hover:scale-110 transform-gpu transition duration-100 ease-linear"
          >
            add_circle
          </span>
        </div> -->
          </div>
          <table>
            <tbody>
              <tr
                v-for="(
                  responsibility, index
                ) in jobSpecification.responsibilities"
                :key="responsibility.id"
                class=""
              >
                <td
                  class="
                    py-4
                    px-6
                    text-sm text-center text-grey
                    border
                    bg-white
                  "
                >
                  {{ index + 1 }}
                </td>
                <td class="py-4 px-6 text-sm text-grey border w-full bg-white pointer-events-none">
                  <input
                    class="w-full"
                    type="text"
                    v-model="responsibility.responsibility"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Person Specification -->
        <div class="flex flex-col w-11/12 m-auto">
          <div class="flex justify-between items-center text-grey">
            <h1 class="ml-10 font-semibold my-3">Person Specification:</h1>
            <!-- <div class="flex">
          <p class="text-darkGrey">Add Row</p>
          <span
            @click="addPersonSpec()"
            class="material-icons text-orange ml-3 cursor-pointer hover:scale-110 transform-gpu transition duration-100 ease-linear"
          >
            add_circle
          </span>
        </div> -->
          </div>
          <table>
            <thead>
              <tr>
                <th
                  scope="col"
                  class="border py-3 px-6 text-xs text-left text-teal bg-white"
                >
                  Attributes
                </th>
                <th
                  scope="col"
                  class="border py-3 px-6 text-xs text-left text-teal bg-white"
                >
                  Essential
                </th>
                <th
                  scope="col"
                  class="border py-3 px-6 text-xs text-left text-teal bg-white"
                >
                  Desirable
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="person in personSpecification.person_specification_attributes"
                :key="person.id"
                class=""
              >
                <td class="py-4 px-2 text-sm text-teal border bg-white pointer-events-none">
                  <input
                    class="w-full"
                    type="text"
                    v-model="person.attribute"
                  />
                </td>
                <td
                  class="
                    py-4
                    px-6
                    text-sm
                    font-medium
                    text-grey
                    border
                    bg-white
                    w-1/2
                  "
                >
                  <input
                    class="w-full pointer-events-none"
                    type="text"
                    v-model="person.essential"
                  />
                </td>
                <td
                  class="
                    py-4
                    px-6
                    text-sm
                    font-medium
                    text-grey
                    border
                    bg-white
                    w-1/2
                  "
                >
                  <input
                    class="w-full pointer-events-none"
                    type="text"
                    v-model="person.desirable"
                    :disabled="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["closeModal"],
  props: {
    jobSpecification: {
      type: Object,
      required: true,
    },
    personSpecification: {
      type: Object,
      required: true,
    },
  },
  created() {
    // this.fetchPersonSpecifications();
  },
  data() {
    return {
      isLoading: false,
      openDialogVar: false,
      responsibilities: [
        {
          id: 1,
          responsibility:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
        },
        {
          id: 2,
          responsibility:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
        },
        {
          id: 3,
          responsibility:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
        },
        {
          id: 4,
          responsibility:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
        },
        {
          id: 5,
          responsibility:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
        },
      ],
      personSpec: [
        {
          id: 1,
          attribute: "Education",
          essential: "PhD",
          desirable: "GCSE",
        },
        {
          id: 2,
          attribute: "Experience",
          essential: "SystmOne",
          desirable: "Other Medical System",
        },
        {
          id: 3,
          attribute: "Ability",
          essential: "Vaccine",
          desirable: "Covid Vaccine",
        },
        {
          id: 4,
          attribute: "Equal Opportunities",
          essential: "Be Equal",
          desirable: "Be Super Equal",
        },
      ],
      myNum: 0,
    };
  },
  methods: {
    addResponsibility() {
      this.responsibilities.push({
        id: "",
        responsibility: "",
      });
    },
    deleteResponsibility(index) {
      this.responsibilities.splice(index, 1);
    },
    addPersonSpec() {
      this.personSpec.push({
        id: "",
        attribute: "",
        essential: "",
        desirable: "",
      });
    },
    deletePersonSpec(index) {
      this.personSpec.splice(index, 1);
    },
    // async fetchPersonSpecifications() {
    //   try {
    //     this.isLoading = true;
    //     await this.$store.dispatch("HQnewHire/fetchPersonSpecifications", [
    //       {
    //         status: status,
    //         page,
    //       },
    //     ]);
    //     this.isLoading = false;
    //   } catch (error) {
    //     this.error = error.message || "Something went wrong!";
    //   }
    // },
    closeDialog() {
      this.$emit("closeModal");
    },
    openDialog(){
      this.openDialogVar = true;
    },
  },
};
</script>
